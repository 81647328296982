.App {
  text-align: center;
  background-color: #1e2024;
  min-height: 100vh;
}

.App-logo {
  height: 75px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1e2024;
  min-height: 90vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 65px;
  color: white;
  font-weight: 300;
}

.details {
  font-size: 20px;
  font-weight: 100;
}

.App-link {
  color: #61dafb;
}

